/* requires:
polyfill.js
*/

// display the current year in the footer
document.querySelector('.footer-year').innerHTML = new Date().getFullYear();

const replacePhone = {
  init: function(config) {
    const { utmSourceName, utmCampaignName, oldPhoneNumber, newPhoneNumber, cssSelector } = config;
    if ((!utmSourceName && !utmCampaignName) || !oldPhoneNumber || !newPhoneNumber || !cssSelector) return;
    function readCookie(name) {
      let nameEQ = escape(name) + "=";
      let ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return unescape(c.substring(nameEQ.length, c.length));
      }
      return null;
    }
    function setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
      }
      const domain = location.hostname == "localhost" ? "" : ";domain=." + location.hostname.split('.').reverse()[1] + "." + location.hostname.split('.').reverse()[0];
      const security = location.hostname == "localhost" ? "" : ";SameSite=None; Secure";
      document.cookie = name + "=" + value + expires + ";path=/" + domain + security;
    }
    function getQueryString(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    
    const phoneElements = document.querySelectorAll(cssSelector);
    const utmSourceQuery = getQueryString("utm_source")?.toLowerCase();
    const utmCampaignQuery = getQueryString("utm_campaign")?.toLowerCase();
    const utmSourcCookie = readCookie("asc_utm_source")?.toLowerCase();
    const utmCampaignCookie = readCookie("asc_utm_campaign")?.toLowerCase();
    if (utmSourceQuery) {
      setCookie("asc_utm_source", `${utmSourceQuery}`, 30);
    }
    if (utmCampaignQuery) {
      setCookie("asc_utm_campaign", `${utmCampaignQuery}`, 30);
    }
    // for utmSourceName trigger, default usage
    if (utmSourceName && (utmSourceQuery === utmSourceName?.toLowerCase() || utmSourcCookie === utmSourceName?.toLowerCase())) {
      this.replacePhone(oldPhoneNumber, newPhoneNumber, phoneElements);
    }
    // for campaignName trigger in case PPC wants to use campaign name as the trigger
    if (utmCampaignName && (utmCampaignQuery === utmCampaignName?.toLowerCase() || utmCampaignCookie === utmCampaignName?.toLowerCase())) {
      this.replacePhone(oldPhoneNumber, newPhoneNumber, phoneElements);
    }
  },
  replacePhone: function(oldPhone, newPhone, phoneElements) {
    for (let phoneElement of phoneElements) {
      // console.log(phoneElement.innerHTML)
      const regex = typeof oldPhone === 'string' ? new RegExp(oldPhone, 'g') : oldPhone;
      const html = phoneElement.innerHTML.replace(regex, newPhone);
      // console.log(html)
      phoneElement.innerHTML = html;
      if (phoneElement.href) {
        const regex = new RegExp('-', 'g');
        const hrefPhone = `tel:${newPhone.replace(regex, '')}`;
        phoneElement.href = hrefPhone;
      }
    }
  }
}

// init function needs to have either "utmSourceName" or "utmCampaignName" or have both
replacePhone.init({
  utmSourceName: "facebook",
  // utmCampaignName: "CampaignName",
  oldPhoneNumber: "800-727-7104",
  newPhoneNumber: "855-231-1623",
  cssSelector: ".main-phone"
})

// example usage for "utmCampaignName" as trigger
// replacePhone.init({
//   // utmSourceName: "testSource",
//   utmCampaignName: "CampaignName",
//   oldPhoneNumber: "517-827-4901",
//   newPhoneNumber: "000-000-0000",
//   cssSelector: ".text-theme-primary"
// })