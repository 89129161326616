/* requires:
polyfill.js
*/

const apiBase = 'cartApiBase_replaced_during_build';
const cartUrl = 'cartUrl_replaced_during_build';

const cart = {
  readCookie: (name) => {
    let nameEQ = escape(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return unescape(c.substring(nameEQ.length, c.length));
    }
    return null;
  },
  setCookie: (name, value, days) => {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    }
    const domain = location.hostname == 'localhost' ? '' : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
  },
  init: () => {
    cart.fetchCart();
    cart.updateCartItemCount(cart.getLocalCart);
    cart.updateCartLink()
    cart.initAddToCartBtns()

  },
  fetchCart: () => {
    const cartKey = cart.getCartKeyQuery();
    const url = `${apiBase}/cart/${cartKey}`;
    if (cartKey !== '') {
      fetch(url).then(checkFetchStatus)
      .then(data => {
        // console.log('item count: ', data.item_count);
        if (data.item_count) {
          // console.log(data.items)
          const cartItems = data.items.map(item => {
            if (item.cart_item_data.length === 0 || item.cart_item_data.bundled_items?.length > 0) {
              return item.id
            }
          }).filter(item => item);
          const fetchedCart = {
            ...cart.getLocalCart(),
            itemCount: data.item_count,
            items: cartItems,
          }
          // console.log(fetchedCart)
          sessionStorage.setItem('localCart', JSON.stringify(fetchedCart));
          cart.updateCartItemCount(fetchedCart);
          cart.initAddToCartBtns()
          if (data.cart_key !== cart.readCookie('wp_cart_key')) {
            cart.setCookie('wp_cart_key', data.cart_key, 7)
          }
        }
        else {
          console.log('There are no items in the cart.')
          // clear cart storage and use new session from API
          cart.setCookie('wp_cart_key', data.cart_key, -1);
          sessionStorage.removeItem('localCart');
          cart.updateCartItemCount(cart.getLocalCart);
          cart.updateCartLink()
          cart.initAddToCartBtns()
        }
      })
      .catch(function(err) {
        console.log(error);
      });
    }
  },
  addToCart: (id) => {
    const cartKey = cart.getCartKeyQuery();
    const url = `${apiBase}/cart/add-item/${cartKey}`;
    const opts = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: id,
        quantity: '1'    
      })
    };
  
    fetch(url, opts).then(checkFetchStatus)
      .then(data => {
        // console.log(JSON.stringify(data, null, 2))
        if (data.item_count) {
          // console.log(data.items)
          const cartItems = data.items.map(item => {
            if (item.cart_item_data.length === 0 || item.cart_item_data.bundled_items?.length > 0) {
              return item.id
            }
          }).filter(item => item);
          const fetchedCart = {
            ...cart.getLocalCart(),
            itemCount: data.item_count,
            items: cartItems,
          }
          // console.log(fetchedCart)
          sessionStorage.setItem('localCart', JSON.stringify(fetchedCart));
          cart.updateCartItemCount(fetchedCart);
          if (data.cart_key !== cart.readCookie('wp_cart_key')) {
            cart.setCookie('wp_cart_key', data.cart_key, 7)
          }
          cart.updateCartLink()
          cart.pushAddToCartDatalayer(id)
        }
        else {
          console.log('There are no items in the cart.')
        }
        cart.initAddToCartBtns()
      })
      .catch(function(err) {
        console.log(error)
      });
  },
  pushAddToCartDatalayer: (productId) => {    
    const pushData = (product) => {
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': 'USD',
          'add': {
            'products': [{
              'name': product.name,
              'id': product.id,
              'price': product.price,
              'category': product.category,
              'quantity': 1
            }]
          }
        }
      });
    }
    if (sessionStorage.getItem("productsData")) {
      const product = JSON.parse(sessionStorage.getItem("productsData")).filter(item => item.id === Number(productId))[0];
      pushData(product)
    }
    else {
      return fetch('/products.json').then(checkFetchStatus)
      .then(data => {
        // console.log(data)
        sessionStorage.setItem("productsData", JSON.stringify(data));
        const product = data.filter(item => item.id === Number(productId))[0];
        pushData(product)
      })
    }
  },
  getCartKeyQuery: () => {
    return cart.readCookie('wp_cart_key') ? `?cart_key=${cart.readCookie('wp_cart_key')}` : '';
  },
  getLocalCart: () => {
    return JSON.parse(sessionStorage.getItem('localCart')) || {
      itemCount: 0,
      items: [],
    };
  },
  updateCartItemCount: (cart) => {
    const itemCounts = document.querySelectorAll('a.view-cart .item-count');
    for (let itemCount of itemCounts) {
      // console.log(itemCount)
      itemCount.innerHTML = cart?.itemCount || 0;
    }
  },
  updateCartLink: () => {
    const updateQueryStringParameter = (uri, key, value) => {
      let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
      let separator = uri.indexOf('?') !== -1 ? '&' : '?';
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
      } else {
        return uri + separator + key + '=' + value;
      }
    }
    const cartKey = cart.readCookie('wp_cart_key');
    const viewCartLinks = document.querySelectorAll('a.view-cart');
    for (let viewCartLink of viewCartLinks) {      
      if (cartKey) {
        viewCartLink.href = updateQueryStringParameter(viewCartLink.href,'cart_key', cartKey);
      }
    }
  },
  addBtnEvent: (e) => {
    e.preventDefault();
    // adding 'processing' class
    // console.log(e.currentTarget)
    e.currentTarget.classList.add('processing')
    const productId = e.currentTarget.dataset.productid;
    cart.addToCart(productId, e.currentTarget);

  },
  initAddToCartBtns:  () => {
    // adding addToCart eventlisteners
    const addToCartBtns = document.querySelectorAll('a[data-productid]');
    const localCartSession = cart.readCookie('wp_cart_key');
    for (let addToCartBtn of addToCartBtns) {
      const localCart = cart.getLocalCart();
      if (localCart?.items.includes(Number(addToCartBtn.dataset.productid)) && localCartSession) {
        addToCartBtn.classList.remove('processing', 'btn-primary')
        addToCartBtn.classList.add('btn-success', 'view-cart-btn');
        addToCartBtn.innerHTML = 'View Cart';
        addToCartBtn.href = `${cartUrl}/${cart.getCartKeyQuery()}`;
        addToCartBtn.removeEventListener('click', cart.addBtnEvent, true);
      }
      else {
        addToCartBtn.classList.remove('btn-success', 'view-cart-btn');
        addToCartBtn.classList.add('btn-primary')
        addToCartBtn.innerHTML = 'Add To Cart';
        addToCartBtn.removeEventListener('click', cart.addBtnEvent, true); // to make sure there's only one eventListener per btn
        addToCartBtn.addEventListener('click', cart.addBtnEvent, true);
      }
    }
  },
}

cart.init()

